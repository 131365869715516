import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/repository';
import { isLoggedIn$ } from '@auth/repository/auth.repository';
import { ACCESS_TOKEN, LOGIN_PAGE } from '@shared/constants/ume-constants';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';


export const authGuardFn: CanActivateFn = ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> => {
  const authService = inject( AuthService );
  const _router = inject( Router );

  let token = state.url.slice( state.url.indexOf( ACCESS_TOKEN ) );
  const isToken = token.length > 50;
  token = isToken ? token.slice( ACCESS_TOKEN.length + 1 ) : token;

  if ( state.url && isToken ) {
    // this._router.navigate( [ '/', LOGIN_PAGE, 't', token ] )
    //   .then( () => {
    //     return this.authService.logOutAsync().pipe( map( () => false ) );
    //   } );
    return authService.logOutAsync()
      .pipe(
        tap( () => {
          _router.navigate( [ '/', LOGIN_PAGE, 't', token ] ).then();
        } ),
        map( () => false ),
      );
  }

  return isLoggedIn$
    .pipe(
      tap( ( isLogged: boolean ) => {
        if ( !isLogged ) {
          _router.navigate( [ '/', LOGIN_PAGE ] ).then();
        }
        return of( false );
      } ),
      catchError( ( _ ) => {
        _router.navigate( [ '/', LOGIN_PAGE ] ).then();
        return of( false );
      } ),
    );
};